<template>
	<div class="field">
		<div :class="[showLine?'field_container line':'field_container']" >
			<img class="field_img"  :src="img" />
			<div class="field_title">
				{{title}}
			</div>
			<div class="field_content" v-if="type==1">
				<div class="iconSelect">
					<van-icon name="arrow" size="15" color="#59595C" style="vertical-align: middle;" />
				</div>

			</div>
			<div class="field_content" v-if="type==2">
				<div class="iconSelect">
					<van-switch size="20px" v-model="checked" active-color="#34C759" style="vertical-align: middle;" />
				</div>
			
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Icon,Switch,
	} from 'vant';

	export default {
		components: {
			vanIcon: Icon,
			vanSwitch:Switch,
		},
		props: {
			//标题
			title: {
				type: String,
				default: ''
			},
			//组件类型1 >  2 check(开关)
			type: {
				type: String,
				default: '1'
			},
			img: {
				type: String,
				default: ''
			},
			showLine:{
				type:Boolean,
				default:false
			}
		},
		data(){
			return { checked: false }
		}
	}
</script>

<style lang="less" scoped="scoped">
	.field {
		width: 100%;
		background: white;
		height: auto;
		.line{
			border-bottom: 1px solid #E7E8ED;
		}
		.field_container {
			width: 92%;
			margin: 0 auto;
			box-sizing: border-box;
			display: flex;
			flex-direction: row;
			align-items: center;
			.field_img{
				width: 16px;
			}
			
			.field_title {
				flex: 1;
				font-size:16px;
				font-weight:400;
				color:rgba(49,49,52,1);
				line-height:16px;
				margin-left: 8px;
			}
			
			.field_content {
				width: auto;
				height: auto;
				.iconSelect {
					text-align: right;
					font-size: 15px;
					font-weight: 400;
					color: rgba(213, 213, 213, 1);
					line-height: 46px;
				}
			}
		}

	}
</style>
