<template>
	
	<div class="page">
	
		<div class="search-result">
			<div class="top">
				<div class="name">{{name}}</div>
				<div class="info">{{sex ? sex == 1 ? '男 /' : '女 /' : '' }}  {{age ? `${age}/` : ''}}  {{illType}}</div>
			</div>
			
			<div class="group">
				<div @click="toInfo">
					<item title="基本信息" :showLine="true" type="1" :img="imgs[0]"></item>
				</div>
				<div @click="toIll">
					<item title="病历" :showLine="true" type="1" :img="imgs[1]"></item>
				</div>
				<div @click="toAsk">
					<item title="询问问题" type="1" :img="imgs[2]"></item>
				</div>
			</div>
		
		</div>
	</div>
	
</template>

<script>
	import {toPath} from '@/utils/common'
	import item from '@/components/mine/item';
	import {
		Toast
	} from 'vant';
	export default {
		components: {
			item,
			[Toast.name]: Toast,
		},
		data() {
			return {
				id:null,
				name:null,
				age:null,
				illType:null,
				sex:null,
				status:null,
				imgs: [require('@assets/patient_info.png'),
					require('@assets/patient_ill.png'),
					require('@assets/patient_ask.png')
				],
				
			}
		},
		methods: {
			init(){
				const {
					id,
					sex,
					name,
					illType,
					age,
					status
				}=this.$route.query
				this.id=id;
				this.sex=sex;
                if(this.sex=='男') {
                    this.sex='1';
                }else if(this.sex=='女'){
                    this.sex='2';
                }else{
                    this.sex='';
				}
				this.name=name;
				this.illType=illType;
				this.age=age;
				if(this.age=='null') this.age='';
				this.status=status;
			},
			onLoad() {
				
			},
			toInfo(){
				// this.$router.push({
				// 	path:'/updatePatient',
				// 	query:{
				// 		id:this.id
				// 	}
				// })
				toPath(this,{
					path:'/updatePatient',
					query:{
						id:this.id
					}
				} )
			},
			toIll(){
				// this.$router.push({
				// 	path:'/updateMedicalRecord',
				// 	query:{
				// 		inquiryId:this.id
				// 	}
				// })
				toPath(this,{
					path:'/updateMedicalRecord',
					query:{
						inquiryId:this.id
					}
				} )
			},
			toAsk(){
				if(this.status==0){
					Toast({
						message: "该癌种下没有询问问题",
						// position: 'bottom'
					});
				}else if(this.status==1){
					// this.$router.push({
					// 	path:'/question',
					// 	query:{
					// 		isEdit:1,
					// 		inquiryId:this.id,
					// 	}
					// })
					toPath(this,{
						path:'/question',
						query:{
							isEdit:1,
							inquiryId:this.id
						}
					} )
				}
				
			}

		},
		mounted() {
			this.init();
		}

	}
</script>

<style lang="less" scoped>
	.page{
		min-height: 100vh;
		background: #F7F7F7;
		
	}
	.top{
		background: white;
		padding: 15px;
		.name{
			width:auto;
			height:auto;
			padding-bottom: 15px;
			font-size:18px;
			font-weight:600;
			color:rgba(42,42,46,1);
		}
		.info{
			width:auto;
			height:auto;
			padding-bottom: 30px;
			font-size:16px;
			font-weight:400;
			color:rgba(130,130,132,1);
		}
	}
	.search-result {
		padding-top: 8px;
		
	

	}
</style>
